import {AbitTransparentBlack, AshBlack, VeryWhite} from "../colours/colours";
import {CSSProperties} from "react";

export const MainApp = {
    display: "flex",

    backgroundColor: AshBlack
}

export const ComingSoon = {
    height: "100vh",
    overflow: "hidden",

    color: VeryWhite,
    backgroundColor: AshBlack
}

export const BlackTransparentBox: CSSProperties = {
    position: "absolute",
    padding: 30,
    left: "50%",
    top: "50%",
    transform: `translate(-50%, -50%)`,

    backgroundColor: AbitTransparentBlack
}
