import React from "react"

import {ComingSoon as ComingSoonStyle, BlackTransparentBox as BoxStyle} from "../constants/styles/styles"
import SignOff from "../constants/SignOff";
import Particles from "react-particles-js";

const ComingSoon: React.FC = () => {
    return (
        <div style={ComingSoonStyle}>
            <div className={"animate__animated animate__fadeIn"} style={{...BoxStyle, MozUserSelect: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none"}}>
                <h2>Hey,</h2>
                <h2>Thanks for stopping by.</h2>
                <p>We'll be with you very soon.</p>
                <SignOff/>
            </div>
            <Particles width={"100vw"} height={"100vh"} params={{
                detectRetina: true,
                interactivity: {
                  events: {
                      onClick: {
                          enable: true,
                          mode: "push"
                      }
                  }
                },
                particles: {
                    links: {
                        distance: 60,
                        enable: true,
                        width: 0.5
                    },
                    number: {
                        density: {
                          enable: true,
                          area: 400
                        },
                        limit: 250,
                        value: 200,
                    },
                }
            }}/>
        </div>
    )
}

export default ComingSoon
