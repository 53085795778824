import React from 'react';
import './App.css';
import 'animate.css'

import {MainApp as MainAppStyle} from "./constants/styles/styles"
import ComingSoon from "./pages/ComingSoon";

function App() {
  return (
      <div style={MainAppStyle}>
          <ComingSoon/>
      </div>
  );
}

export default App;
